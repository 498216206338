import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import "../assets/css/contact-form-styles.css"

const ContactForm = ({ backgroundImage, nrTel = "32 212 4191" }) => {
  return (
    <main id="contact" className="contactForm">
      <GatsbyImage
        image={backgroundImage}
        alt="form"
        className="formBackground"
      ></GatsbyImage>
      <div className="formContainer">
        <div className="formInfo">
          <h3>
            EKa Sp. z o. o.
            <br />
            ul. Św Jana 13, 43-267 Suszec
            <br />
            tel. {nrTel}
            <br />
          </h3>
        </div>
        <form
          method="post"
          action="https://getform.io/f/447a19b8-db9d-43cb-b4ed-a9462e01cd58"
        >
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            placeholder="Imię i Nazwisko / Firma:"
          />
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            placeholder="E-mail:"
          />
          <input
            type="tel"
            id="phone"
            name="phone"
            className="form-control"
            placeholder="Telefon:"
          />
          <input
            type="textarea"
            rows="3"
            id="msg"
            name="msg"
            className="form-control"
            placeholder="Wiadomość:"
          />
          <button type="submit">Wyślij</button>
        </form>
      </div>
    </main>
  )
}

export default ContactForm
