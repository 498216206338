import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BudowaPanelu = () => {
  return (
    <div id="budowa_panelu">
      <div className="fotowoltaikaElement">
        <StaticImage
          src="../../assets/images/fotowoltaika/budowa_panelu.png"
          alt="nudowa_panelu"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="title">
          budowa
          <br />
          panelu
        </div>
        <div className="text-holder" lang="en">
          <h3>INSTALACJE FOTOWOLTAICZNE</h3>
          <p>
            Ogniwa fotowoltaiczne to ekologiczne źródło energii elektrycznej.
            Pozwalają one pozyskać energię ze słońca. Nasza firma proponuje
            usługę obejmującą fachowe doradztwo w zakresie doboru odpowiedniej
            mocy instalacji, zgodnej z zapotrzebowaniem oraz odpowiedniego
            systemu montażowego.
          </p>
          <p>
            Oferujemy także kompleksową obsługę związaną z przygotowaniem
            niezbędnej dokumentacji i składaniem wniosku o przyłączenie
            instalacji do sieci. Dodatkowo nasi specjaliści skonfigurują
            urządzenia oraz zainstalują aplikacje, pomagające w kontrolowaniu
            efektywności instalacji.
          </p>
          <p>
            Wykonujemy instalacje dla klientów indywidualnych oraz
            przedsiębiorstw. Wybór i sposób montażu paneli fotowoltaicznych jest
            zależny od warunków technicznych. Przed przystąpieniem do montażu
            paneli, nasi fachowcy przeprowadzają oględziny miejsca, aby
            zaproponować najbardziej optymalne rozwiązanie i zagwarantować
            wysoką efektywność, bezpieczeństwo i jakość instalacji.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BudowaPanelu
