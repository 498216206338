import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Footer = ({ nrTel = "(32) 448 82 01", email = "eka@eka.pl" }) => {
  return (
    <footer id="footer">
      <div className="footerContainer">
        <div className="footerColumn">
          <h3>Zobacz również:</h3>
          <Link to="/sklep_techniczny">
            <StaticImage
              src="../assets/images/footer-icons/stopka_dzial_techniczny.png"
              alt="dzial_techniczny"
              placeholder="tracedSVG"
              layout="fixed"
              className="footerIcon"
            />
            <h3>DZIAŁ TECHNICZNY</h3>
          </Link>
          <Link to="/ekologiczna_energia">
            <StaticImage
              src="../assets/images/footer-icons/stopka_OZE.png"
              alt="OZE"
              placeholder="tracedSVG"
              layout="fixed"
              className="footerIcon"
            />
            <h3>EKOLOGICZNE ŹRÓDŁA ENERGII</h3>
          </Link>
          <Link to="/produkcja_zywnosci">
            <StaticImage
              src="../assets/images/footer-icons/stopka_produkcja_zywnosci.png"
              alt="produkcja_zywbosci"
              placeholder="tracedSVG"
              layout="fixed"
              className="footerIcon"
            />
            <h3>PRODUKCJA ŻYWNOŚCI</h3>
          </Link>
        </div>
        <div className="footerColumn">
          <h3>Kontakt:</h3>
          <StaticImage
            src="../assets/images/footer-icons/stopka_telefon.png"
            alt="telefon"
            placeholder="tracedSVG"
            layout="fixed"
            className="footerIcon"
          />
          <h3>tel.: {nrTel}</h3>
          <StaticImage
            src="../assets/images/footer-icons/stopka_email.png"
            alt="email"
            placeholder="tracedSVG"
            layout="fixed"
            className="footerIcon"
          />
          <h3>e-mail: {email}</h3>
          <StaticImage
            src="../assets/images/footer-icons/stopka_adres.png"
            alt="adres"
            placeholder="tracedSVG"
            layout="fixed"
            className="footerIcon"
          />
          <h3>
            Eka Sp. z o.o., ul. Św. Jana 13, Suszec
            <br />
            ul. Pszczyńska 74, Suszec
          </h3>
        </div>
      </div>
    </footer>
  )
}

export default Footer
