import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

import SideMenu from "../SideMenu"

import "../../assets/css/fotowoltaika.css"

const Menu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/fotowoltaika#budowa_panelu"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/fotowoltaika/menu/budowa_panelu.png"
            alt="budowa_panelu"
            placeholder="tracedSVG"
          />
          <h3>budowa panelu</h3>
        </AnchorLink>
        <AnchorLink
          to="/fotowoltaika#montaz"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/fotowoltaika/menu/montaz.png"
            alt="montaz"
            placeholder="tracedSVG"
          />
          <h3>montaż</h3>
        </AnchorLink>
        <AnchorLink
          to="/fotowoltaika#rodzaje_paneli"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/fotowoltaika/menu/rodzaje_paneli.png"
            alt="rodzaje_paneli"
            placeholder="tracedSVG"
          />
          <h3>rodzaje paneli</h3>
        </AnchorLink>
        <AnchorLink
          to="/fotowoltaika#falowniki"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/fotowoltaika/menu/falowniki.png"
            alt="falowniki"
            placeholder="tracedSVG"
          />
          <h3>falowniki</h3>
        </AnchorLink>
      </div>
      <SideMenu base="/fotowoltaika#fotowoltaika_top">
        <div className="sideMenuElement">
          <h3>budowa panelu</h3>
          <AnchorLink to="/fotowoltaika#budowa_panelu">
            <StaticImage
              src="../../assets/images/fotowoltaika/menu/budowa_panelu.png"
              alt="budowa_panelu"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>montaż</h3>
          <AnchorLink to="/fotowoltaika#montaz">
            <StaticImage
              src="../../assets/images/fotowoltaika/menu/montaz.png"
              alt="montaz"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>rodzaje paneli</h3>
          <AnchorLink to="/fotowoltaika#rodzaje_paneli">
            <StaticImage
              src="../../assets/images/fotowoltaika/menu/rodzaje_paneli.png"
              alt="rodzaje_paneli"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>falowniki</h3>
          <AnchorLink to="/fotowoltaika#falowniki">
            <StaticImage
              src="../../assets/images/fotowoltaika/menu/falowniki.png"
              alt="falowniki"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
          <StaticImage
            src="../../assets/images/oze/oze_back.png"
            alt="sterowanie"
            placeholder="tracedSVG"
          />
        </Link>
      </SideMenu>
    </>
  )
}

export default Menu
