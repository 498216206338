import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Menu from "../components/fotowoltaika/Menu"
import BudowaPanelu from "../components/fotowoltaika/BudowaPanelu"
import Montaz from "../components/fotowoltaika/Montaz"
import RodzajePaneli from "../components/fotowoltaika/RodzajePaneli"
import Falowniki from "../components/fotowoltaika/Falowniki"
import Seo from "../components/Seo"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "fotowoltaika" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "fotowoltaika" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Fotowoltaika = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="fotowoltaika_top" className="page fotowoltaikaPage">
      <Seo title="Fotowoltaika" />
      <Top image={imgTop} />
      <Menu />
      <BudowaPanelu />
      <Montaz />
      <RodzajePaneli />
      <Falowniki />
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
    </div>
  )
}

export default Fotowoltaika
