import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const RodzajePaneli = () => {
  return (
    <div id="rodzaje_paneli">
      <div className="fotowoltaikaElement">
        <div className="title">rodzaje paneli fotowoltaicznych</div>
        <div className="text-holder">
          <p>
            Ogniwa, z których zbudowane są moduły fotowoltaiczne, wytwarza się z
            mono- lub polikrystalicznego krzemu. Dzięki krzemowej płytce,
            możliwe jest wytwarzanie prądu. Panele monokrystaliczne to
            najbardziej zaawansowane ogniwa, zbudowane z monolitycznego,
            pojedynczego kryształu krzemu. W panelach polikrystalicznych krzem
            jest krystalizowany i łączony w dużą bryłę. Te panele cechuje
            mniejsza moc w przełożeniu na powierzchnię w porównaniu do paneli
            monokrystalicznych. Pracujemy na panelach uznanych marek: JINKO
            Solar, LONGI Solar, JUST Solar, DAH Solar.
          </p>
        </div>
        <StaticImage
          src="../../assets/images/fotowoltaika/rodzaje_paneli_panele.png"
          alt="rodzaje_paneli"
          placeholder="tracedSVG"
          className="rodzajePaneliObraz"
        />
        <StaticImage
          src="../../assets/images/fotowoltaika/rodzaje_paneli_loga.png"
          alt="rodzaje_paneli_loga"
          placeholder="tracedSVG"
          className="rodzajePaneliLogaObraz"
        />
      </div>
    </div>
  )
}

export default RodzajePaneli
