import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Falowniki = () => {
  return (
    <div id="falowniki">
      <div className="fotowoltaikaElement">
        <StaticImage
          src="../../assets/images/fotowoltaika/falowniki.png"
          alt="falowniki"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>FALOWNIKI</h3>
          <p>
            Falownik to bardzo ważny element składowy instalacji, ma kluczowy
            wpływ na poprawne działanie instalacji oraz jej efektywność.
            Odpowiednio dobrany inwerter pozwala zoptymalizować wydajność
            paneli. Falownik odpowiada za synchronizację instalacji z siecią
            oraz pozwala na monitorowanie jej funkcjonowania. W naszych
            instalacjach stasujemy falowniki marek: Afore oraz Huawei.
          </p>
        </div>
        <StaticImage
          src="../../assets/images/fotowoltaika/falowniki_loga.png"
          alt="falowniki_loga"
          placeholder="tracedSVG"
          className="falownikiLoga"
        />
      </div>
    </div>
  )
}

export default Falowniki
