import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Montaz = () => {
  return (
    <div id="montaz">
      <div className="fotowoltaikaElement">
        <div className="title">
          <h3>montaż paneli na dachu spadzistym</h3>
        </div>
        <StaticImage
          src="../../assets/images/fotowoltaika/montaz_na_dachu.png"
          alt="montaz_gont_papa"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="montazNaDachu">
          <div className="montazNaDachuElement">
            <h3>gont i papa</h3>
          </div>
          <div className="montazNaDachuElement">
            <h3>dachówka karpiówka</h3>
          </div>
          <div className="montazNaDachuElement">
            <h3>dachowka falista</h3>
          </div>
        </div>
        <div className="montazNaDachuPlaskim">
          <div className="title">
            <h3>montaż paneli na dachu płaskim</h3>
          </div>
          <div className="montazNaDachuPlaskimElement">
            <div className="montazNaDachuPlaskimElementText">
              <p>
                <div className="subtitle">konstrukcje balastowe</div>
                system bezinwazyjny
                <br />z zastosowaniem
                <br />
                betonowych obciążników
              </p>
            </div>
          </div>
          <div className="montazNaDachuPlaskimElement">
            <div className="montazNaDachuPlaskimElementText">
              <p>
                <div className="subtitle">konstrukcje wsporcze - ekierki</div>
                profile wkręcane
                <br />w poszycie dachowe
              </p>
            </div>
          </div>
        </div>
        <div className="text-holder" lang="en">
          <h3>NA CO ZWRÓCIĆ UWAGĘ PODCZAS MONTAŻU</h3>
          <p>
            <b>&#8226; ORIENTACJA</b> – optymalne usytuowanie paneli to
            skierowanie ich na południe; w przypadku gdy otoczenie i warunki
            wymuszają inne ułożenie (wschód, zachód), należy przewidzieć
            zmniejszoną wydajność instalacji.
          </p>
          <p>
            <b>&#8226; KĄT NACHYLENIA DACHU</b> – najbardziej optymalnym kątem
            jest 25°-40°, jednak możliwy jest montaż instalacji na niemal każdym
            rodzaju dachu.
          </p>
          <p>
            <b>&#8226; POWIERZCHNIA DACHU</b> – determinuje ilość montowanych
            paneli, a w konsekwencji – moc instalacji.
          </p>
          <p>
            <b>&#8226; RODZAJ POKRYCIA DACHOWEGO</b> – instalację można
            zamontować na blachodachówkach, blachach trapezowych, dachówkach
            ceramicznych, goncie, papie czy membranie.
          </p>
          <p>
            <b>&#8226; STOPIEŃ ZACIENIENIA</b> – wybierając miejsce na montaż
            paneli, należy unikać takiego, które jest narażone na zacienienie,
            np. przez wykusze, kominy, drzewa itp. W przypadku braku możliwości
            uniknięcia zacienienia, zalecane jest zastosowanie optymalizatorów
            mocy.
          </p>
        </div>

        <div className="naGruncie">
          <div className="title">
            <h3>instalacje na gruncie</h3>
          </div>
          <StaticImage
            src="../../assets/images/fotowoltaika/montaz_na_gruncie.png"
            alt="montaz_gont_papa"
            placeholder="tracedSVG"
            className="elementBackground"
          />
          <div className="naGrunciePodpisy">
            <div className="naGruncieElement">wiata garażowa</div>
            <div className="naGruncieElement">konstrukcja na jednej nodze</div>
            <div className="naGruncieElement">konstrukcja na dwóch nogach</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Montaz
