import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SideMenu = ({ base, children }) => {
  return (
    <div className="sideMenu">
      <div className="sideMenuElements">{children}</div>
      <AnchorLink className="sideMenuPageUp" to={base}>
        <StaticImage
          src="../assets/images/home.png"
          alt="page_up"
          placeholder="tracedSVG"
        />
      </AnchorLink>
    </div>
  )
}

export default SideMenu
