import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import "../assets/css/top-styles.css"
import { Link } from "gatsby"
import TopMenu from "./TopMenu"

const Top = ({ image, children }) => {
  return (
    <div className="top">
      <GatsbyImage image={image} alt="top" layout="fullWidth"></GatsbyImage>
      <div className="topContainer">
        {children}
        <div className="topImage">
          <Link to="/">
            <StaticImage
              src="../assets/images/logo.png"
              alt="logo"
              placeholder="tracedSVG"
            ></StaticImage>
          </Link>
        </div>
        <TopMenu />
      </div>
    </div>
  )
}

export default Top
